<template>
	<Toast />
	<Utils ref='Utils' v-show='false' />
	<div class="card p-grid p-fluid" height='100%'>
		<div class="p-col-12 p-md-3">
			<pageNumInput ref='textPageNumInput' :op_type_copy='1' @startSearch='getText' @initialWords='InitialWords' />
			<div class="card-gray" :style='search_box_style'>
				<div class="card">
					<!-- <h6 style="color: var(--primary-color);" @click="translateChinesePara">翻译 o(一︿一+)o</h6> -->
					<Button label="翻译 o(一︿一+)o" style="width: 150px;margin-bottom: 5px;margin-top: 2px;"  class="p-button-info p-button-outlined" @click="translateChinesePara" />
					<Textarea placeholder="可输入中文后点击上方翻译搜索" id="chinese_para" :value="chinsese_text"  @input="chineseSearchSensBlur" :autoResize="true" rows="3" cols="30" />
				</div>
				<div class="card" style="margin-top: 0px;">
					 <h6 style="color: var(--primary-color);">搜索关键词┑(￣Д ￣)┍</h6>
					<Textarea placeholder="可输入中英文关键词或段落进行搜索"  id="search_sens_input" v-model:value="search_sens" @input="SearchSensBlur"
						v-on:keyup.enter="getRelativeText" :autoResize="true" rows="3" cols="30" />
				</div>
			</div>
		</div>
		<div class="p-col-12 p-md-9">
			<div class="card-gray" :style='text_box_style' id="result_container">
				<h6 style="vertical-align: middle;">期刊原文 ╰(*°▽°*)╯ﾞ</h6>
				<div style="background-color: #ffffff;" v-for="(item, j) of relative_text_list" :key="j">
					<div class="item-title"><span>第{{item.page_num+1}}页</span><i class="pi pi-sign-out" style="float: right;"
							@click="openOriginPDF(j)"></i><i title='复制段落文本' class="pi pi-book"
							style="float: right;margin-right: 0.80em;" @click="copyToClipboard(item.img_name)"></i></div>
					<p class="word-item" v-html="item.img_name"></p>
					<p v-if="item.show_translate" class="word-item">{{item.translate_text}}</p>
				</div>
				<div style="background-color: #ffffff;" v-if='relative_text_list.length==0'>
					<p style="padding: 10px;">没有相关原文段落</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Utils from './Utils.vue';
	import pageNumInput from './userComponents/pageNumInput.vue';
	import TranslateService from '../service/TransLateService.js';
	import NetWorkService from '../service/NetWorkService.js';
	export default {
		name:'essayPolishing',
		data() {
			return {				
				select_item:{},
				chinese_text:'',
				pdf_page_num: null,
				pdf_last_disabled: true,
				text_result_page_num: 1,
				total_text_result_page_num: 1,
				relative_text_list: [],
				pdf_path: '',
				search_sens: '',
				visibleRight: false,
				//图片列表
				search_box_style: {
					"height": "700px",
					"overflow-y": "auto",
					"overflow-x": "hidden"
				},
				text_box_style: {
					"height": "700px",
					"overflow-y": "auto",
					"overflow-x": "hidden"
				},
			}
		},
		components: {
			'Utils': Utils,
			'pageNumInput':pageNumInput,
		},
		created() {
			this.translateService = new TranslateService();
			this.networkService = new NetWorkService();
		},
		mounted() {
			let vm = this;
			// vm.getSelectText();
			vm.isMobile();
			vm.resizeWin();
			//屏幕变化时
			window.onresize = () => {
				return (() => {
					vm.resizeWin();
				})();
			};
		},
		methods: {
			 //复制内容到剪切板
			 copyToClipboard(src) {
			 	this.$refs.Utils.copyToClipboard(src);
			 },
			//结果框滑动到最上面
			scrollToTop(){
				this.resizeWin();
				document.getElementById('result_container').scrollTop =0;
			},
			goToReadPaperPage(item){
				this.$router.push({
					name: 'readPaper',
					params: {
					  pdf_path: this.pdf_path, 
					  current_page: item.page_num + 1,
					  total_page: item.total_page,
					  read_single_pdf: item.whole_file,
					  pdf_name:item.pdf_name,
					}
				})
			},
			openOriginPDF(index) {
				this.visibleRight = true;
				this.pdf_path = this.relative_text_list[index].pdf_src;
				this.select_item=this.relative_text_list[index];
				// this.$refs.paperPageNumInput.total_page_num=this.select_item.total_page;
				// this.$refs.paperPageNumInput.current_page_num=this.select_item.page_num+1;
				// this.flushPdfPath(0);
				this.goToReadPaperPage(this.select_item);
			},
			flushPdfPath(delta) {
				this.$refs.singlePapers.viewerReset();
				this.pdf_path=this.$refs.Utils.getNeiborPage(this.pdf_path,delta);
			},
			//获取指定页码
			getTargetPage(){
				this.$refs.singlePapers.viewerReset(); //重置阅读文档页面
				this.pdf_path=this.$refs.Utils.getTargetPage(this.pdf_path,this.$refs.paperPageNumInput.current_page_num);
			},
			closeTranslate(index) {
				//图片表述列表的对象翻译
				this.relative_text_list[index].show_translate = false;
			},
			getRelativeText(){
				this.$refs.textPageNumInput.initialSearch();
			},
			async translateselectImgItem(index) {
				if (this.relative_text_list[index].translate_text !== '') {
					this.relative_text_list[index].show_translate = true;
					return;
				}
				this.$appState.processing=true;
				var sentents = this.relative_text_list[index].img_name;
				var resp=await this.$refs.Utils.translateSents(sentents);
				
				this.relative_text_list[index].translate_text = resp.data.translate_text;
				this.relative_text_list[index].show_translate = true;
				this.$appState.processing=false;
			},
			async translateChinesePara(){
				this.$appState.processing=true;
				var resp=await this.$refs.Utils.translateSents(this.chinese_text);
				this.search_sens = resp.data.translate_text;
				this.getRelativeText();
				this.$appState.processing=false;
			},
			InitialWords() {
				document.getElementById("search_sens_input").value = '';
				this.search_sens='';
				document.getElementById("chinese_para").value = '';
				this.chinese_text='';
			},
			chineseSearchSensBlur(){
				var word_input = document.getElementById("chinese_para");
				var keyword = word_input.value;
				if (!keyword) {
					this.InitialWords();
					return;
				}
				this.chinese_text = keyword;
			},
			//输入
			SearchSensBlur() {
				var word_input = document.getElementById("search_sens_input");
				var keyword = word_input.value;
				if (!keyword) {
					this.InitialWords();
					return;
				}
				this.search_sens = keyword;
			},
			//查询图片
			async getText() {
				this.$appState.processing=true;
				this.processing = true;
				// var word_input = document.getElementById("search_sens_input");
				var resp=await this.$refs.Utils.getText(this.search_sens,this.$refs.textPageNumInput.current_page_num);
				this.relative_text_list = resp.data.relative_text_list;
				this.$refs.textPageNumInput.total_page_num = resp.data.total_page;
				this.scrollToTop();//结果框滑动到最上面
				this.processing = false;
				this.$appState.processing=false;
			},
			// 添加判断方法
			isMobile() {
				this.flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				this.current_page = 1;
				if (this.flag === null) {
					this.rightNavShow = true;
					this.resizeWin();
				}
			},
			//屏幕变化调整样式
			resizeWin() {
				this.center_width = document.documentElement.scrollWidth;
				if (this.center_width <= 750) {
					this.search_box_style.height = "auto";
					this.text_box_style.height = "auto";
				} else {
					var windowHeight = document.documentElement.clientHeight;
					var main_height = windowHeight - 160 + 'px';
					this.search_box_style.height =windowHeight - 208 + 'px';
					this.text_box_style.height = main_height;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	::v-deep(.p-multiselect) {
		min-width: 15rem;
	}

	.multiselect-custom-virtual-scroll .p-multiselect {
		min-width: 20rem;
	}

	::v-deep(.multiselect-custom .p-multiselect-label) {
		padding-top: .25rem;
		padding-bottom: .25rem;
	}

	.multiselect-custom .country-item.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
	}

	.multiselect-custom .country-item.country-item-value span.flag {
		width: 17px;
	}

	.country-item {
		display: flex;
		align-items: center;
	}

	.country-item span.flag {
		width: 18px;
		height: 12px;
		margin-right: .5rem;
	}

	.multiselect-custom .country-placeholder {
		padding: 0.25rem;
	}


	.item-title {
		color: var(--primary-color);
		padding: 3px 3px 3px 8px;
	}

	.word-item {
		padding: 3px 3px 3px 8px;
		margin-bottom: 5px;
		margin-top: 0px;
	}

	// .word-item:hover {
	// 	background-color: #1fa1fc;
	// 	color: #ffffff;
	// }
</style>

